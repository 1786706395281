.bookstore-experience {
    align-items: center;
    background-color: #a7f3d0;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  .bookstore-experience .type-simple-website {
    align-items: center;
    background-color: #ecfdf5;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 8px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 5;
  }
  
  .bookstore-experience .brand {
    align-items: center;
    display: flex;
    gap: 8px;
    height: 42px;
    padding: 0px 8px;
    position: relative;
    width: 394px;
  }
  
  .bookstore-experience .img {
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .bookstore-experience .circlepos-com-normal {
    height: 45.67px;
    margin-bottom: -1.84px;
    margin-top: -1.84px;
    object-fit: cover;
    position: relative;
    width: 200px;
  }
  
  .bookstore-experience .nav-links {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-end;
    position: relative;
  }
  
  .bookstore-experience .nav-link {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 0px 16px;
    position: relative;
  }
  
  .bookstore-experience .nav-label {
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .button {
    all: unset;
    align-items: center;
    border: 1px solid;
    border-color: #064e3b;
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 43px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
  }
  
  .bookstore-experience .text-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 0px 4px;
    position: relative;
  }
  
  .bookstore-experience .CTA {
    all: unset;
    box-sizing: border-box;
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    text-shadow: 0px 4px 4px #00000040;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .text-container-wrapper {
    all: unset;
    align-items: center;
    background-color: #65a30d;
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
  }
  
  .bookstore-experience .text-wrapper {
    all: unset;
    box-sizing: border-box;
    color: #ecfdf5;
    font-family: "Open Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .type-stacked-medium {
    align-items: center;
    align-self: stretch;
    background-color: #ecfdf5;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    padding: 120px 40px;
    position: relative;
    width: 100%;
    z-index: 4;
  }
  
  .bookstore-experience .content {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 64px;
    max-width: 1280px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .div {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 40px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .figma-ipsum {
    align-self: stretch;
    color: #064e3b;
    font-family: "Domine", Helvetica;
    font-size: 56px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 67.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .bookstore-experience .p {
    align-self: stretch;
    color: #059669;
    font-family: "Inter", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 28.6px;
    position: relative;
    text-align: center;
  }
  
  .bookstore-experience .button-combo {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 16px 16px;
    max-width: 420px;
    position: relative;
  }
  
  .bookstore-experience .CTA-button {
    align-items: center;
    background-color: #65a30d;
    border-radius: 8px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 64px;
    justify-content: center;
    padding: 20px 28px;
    position: relative;
  }
  
  .bookstore-experience .CTA-wrapper {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    margin-bottom: -4px;
    margin-top: -4px;
    padding: 4px;
    position: relative;
  }
  
  .bookstore-experience .CTA-2 {
    all: unset;
    box-sizing: border-box;
    color: #ecfdf5;
    font-family: "Open Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    cursor: pointer;
  }
  
  .bookstore-experience .image-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .image-lummi {
    align-self: stretch;
    height: 600px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .property-image {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 120px 16px;
    position: relative;
    width: 100%;
    z-index: 3;
  }
  
  .bookstore-experience .image-lummi-2 {
    height: 686px;
    left: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  
  .bookstore-experience .copy-component {
    align-items: center;
    align-self: stretch;
    background-color: #ecfdf5;
    border-radius: 32px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    max-width: 480px;
    padding: 32px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .heading-container {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .sign-up-heading {
    align-self: stretch;
    color: #064e3b;
    font-family: "Domine", Helvetica;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 46.8px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .bookstore-experience .subheading {
    align-self: stretch;
    color: #059669;
    font-family: "Open Sans", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    position: relative;
    text-align: center;
  }
  
  .bookstore-experience .google-login {
    align-items: center;
    align-self: stretch;
    background-color: #dcfce7;
    border: 1px solid;
    border-color: #a7f3d0;
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    gap: 12px;
    justify-content: center;
    padding: 16px 8px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .continue-with-google {
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .separator {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 24px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .line {
    background-color: #a7f3d0;
    flex: 1;
    flex-grow: 1;
    height: 1px;
    position: relative;
  }
  
  .bookstore-experience .text-wrapper-2 {
    color: #059669;
    font-family: "Open Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .form {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .input {
    align-items: flex-start;
    align-self: stretch;
    border-radius: 8px;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .label {
    align-self: stretch;
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
  }
  
  .bookstore-experience .input-form {
    align-items: center;
    align-self: stretch;
    background-color: #ecfdf5;
    border: 1px solid;
    border-color: #a7f3d0;
    border-radius: 4px;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    padding: 16px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .placeholder-text-wrapper {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    padding: 0px 4px;
    position: relative;
  }
  
  .bookstore-experience .placeholder-text {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    color: #059669;
    display: -webkit-box;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .div-wrapper {
    all: unset;
    align-items: center;
    align-self: stretch;
    background-color: #65a30d;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    padding: 16px 24px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .text-container-2 {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    height: 22px;
    justify-content: center;
    padding: 0px 4px;
    position: relative;
  }
  
  .bookstore-experience .CTA-3 {
    all: unset;
    box-sizing: border-box;
    color: #ecfdf5;
    flex: 1;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
  }
  
  .bookstore-experience .by-continuing-you {
    align-self: stretch;
    color: #059669;
    font-family: "Open Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    position: relative;
    text-align: center;
  }
  
  .bookstore-experience .property-image-2 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #ecfdf5;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 0px 0px;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 2;
  }
  
  .bookstore-experience .contact-image-lummi {
    background-image: url(https://c.animaapp.com/b29AHa7X/img/contact-image----lummi.svg);
    background-position: 50% 50%;
    background-size: cover;
    flex: 1;
    flex-grow: 1;
    height: 803px;
    min-width: 320px;
    position: relative;
  }
  
  .bookstore-experience .contact-copy {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    height: 803px;
    justify-content: center;
    min-width: 320px;
    padding: 80px;
    position: relative;
  }
  
  .bookstore-experience .copy {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    margin-top: -6px;
    max-width: 800px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .heading {
    align-self: stretch;
    color: #064e3b;
    font-family: "Domine", Helvetica;
    font-size: 56px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 67.2px;
    margin-top: -1px;
    position: relative;
  }
  
  .bookstore-experience .description {
    align-self: stretch;
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30.8px;
    position: relative;
  }
  
  .bookstore-experience .input-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    min-width: 230px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .input-form-2 {
    align-items: center;
    align-self: stretch;
    background-color: #ecfdf5;
    border: 1px solid;
    border-color: #a7f3d0;
    border-radius: 8px;
    display: flex;
    gap: 8px;
    height: 56px;
    padding: 16px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .text-container-3 {
    align-items: center;
    display: flex;
    gap: 8px;
    padding: 0px 4px;
    position: relative;
    width: 171px;
  }
  
  .bookstore-experience .input-3 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 4px;
    height: 200px;
    justify-content: center;
    min-width: 230px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .input-form-3 {
    align-items: flex-start;
    align-self: stretch;
    background-color: #ecfdf5;
    border: 1px solid;
    border-color: #a7f3d0;
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 8px;
    padding: 16px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .button-2 {
    all: unset;
    align-items: center;
    background-color: #65a30d;
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 4px;
    height: 53px;
    justify-content: center;
    margin-bottom: -6px;
    padding: 16px 24px;
    position: relative;
  }
  
  .bookstore-experience .text-container-4 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    margin-bottom: -4.5px;
    margin-top: -4.5px;
    padding: 4px;
    position: relative;
  }
  
  .bookstore-experience .CTA-4 {
    all: unset;
    box-sizing: border-box;
    color: #ecfdf5;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .type-single-row-CTA {
    align-items: center;
    align-self: stretch;
    background-color: #ecfdf5;
    display: flex;
    flex: 0 0 auto;
    gap: 48px;
    justify-content: center;
    padding: 120px 16px;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  
  .bookstore-experience .container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: 48px 48px;
    justify-content: center;
    max-width: 1200px;
    position: relative;
  }
  
  .bookstore-experience .copy-component-2 {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 40px;
    justify-content: center;
    max-width: 720px;
    min-width: 304px;
    position: relative;
  }
  
  .bookstore-experience .heading-text {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .heading-2 {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    align-self: stretch;
    color: #064e3b;
    display: -webkit-box;
    font-family: "Domine", Helvetica;
    font-size: 56px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 67.2px;
    margin-top: -1px;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
  }
  
  .bookstore-experience .subheading-2 {
    align-self: stretch;
    color: #059669;
    font-family: "Open Sans", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 30.8px;
    position: relative;
  }
  
  .bookstore-experience .button-combo-2 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    gap: 16px 16px;
    max-width: 680px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .CTA-button-2 {
    all: unset;
    align-items: center;
    background-color: #65a30d;
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 4px;
    height: 53px;
    justify-content: center;
    min-width: 140px;
    padding: 16px 24px;
    position: relative;
  }
  
  .bookstore-experience .text-container-5 {
    all: unset;
    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    margin-bottom: -4.5px;
    margin-top: -4.5px;
    padding: 4px;
    position: relative;
  }
  
  .bookstore-experience .secondary-button {
    align-items: center;
    border: 1px solid;
    border-color: #34d399;
    border-radius: 8px;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 4px;
    height: 53px;
    justify-content: center;
    min-width: 140px;
    padding: 16px 24px;
    position: relative;
  }
  
  .bookstore-experience .text-container-6 {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    margin-bottom: -0.5px;
    margin-top: -0.5px;
    padding: 0px 4px;
    position: relative;
  }
  
  .bookstore-experience .CTA-5 {
    all: unset;
    box-sizing: border-box;
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .image-lummi-3 {
    background-image: url(https://c.animaapp.com/b29AHa7X/img/image----lummi-1.svg);
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 48px;
    flex: 1;
    flex-grow: 1;
    height: 412px;
    max-height: 520px;
    min-width: 280px;
    position: relative;
  }
  
  .bookstore-experience .type-simple {
    align-items: center;
    align-self: stretch;
    background-color: #ecfdf5;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 64px;
    padding: 80px 64px;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  
  .bookstore-experience .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .column {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  
  .bookstore-experience .logo {
    flex: 0 0 auto;
    position: relative;
  }
  
  .bookstore-experience .links {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
  }
  
  .bookstore-experience .link {
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .actions {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 400px;
  }
  
  .bookstore-experience .heading-3 {
    align-self: stretch;
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: -1px;
    position: relative;
  }
  
  .bookstore-experience .form-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .text-input {
    background-color: #ecfdf5;
    border: 1px solid;
    border-color: #064e3b;
    color: #059669;
    flex: 1;
    flex-grow: 1;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-top: -1px;
    padding: 12px;
    position: relative;
  }
  
  .bookstore-experience .button-wrapper {
    all: unset;
    align-items: center;
    border: 1px solid;
    border-color: #064e3b;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    margin-bottom: -1px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 12px 24px;
    position: relative;
  }
  
  .bookstore-experience .button-3 {
    all: unset;
    box-sizing: border-box;
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .bookstore-experience .text {
    align-self: stretch;
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    position: relative;
  }
  
  .bookstore-experience .credits {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .divider {
    align-self: stretch;
    background-color: #064e3b;
    height: 1px;
    position: relative;
    width: 100%;
  }
  
  .bookstore-experience .footer-links {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
  }
  
  .bookstore-experience .text-wrapper-3 {
    color: #064e3b;
    font-family: "Open Sans", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  