body {
    background-color: #ecfdf5;
}

.circle-bookstore {
    align-items: center;
    background-color: #ecfdf5;
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
  }
  
  .circle-bookstore .type-simple-website {
    align-items: center;
    background-color: #ecfdf5;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: 8px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
  
  .circle-bookstore .brand {
    align-items: center;
    display: flex;
    gap: 8px;
    height: 42px;
    padding: 0px 8px;
    position: relative;
    width: 394px;
  }
  
  .circle-bookstore .icon {
    height: 24px;
    position: relative;
    width: 24px;
  }
  
  .circle-bookstore .circlepos-com-normal {
    height: 45.67px;
    margin-bottom: -1.84px;
    margin-top: -1.84px;
    object-fit: cover;
    position: relative;
    width: 200px;
  }
  
  .circle-bookstore .nav-links {
    align-items: center;
    display: flex;
    flex: 1;
    flex-grow: 1;
    gap: 12px;
    justify-content: flex-end;
    position: relative;
  }
  
  .circle-bookstore .nav-link {
    align-items: center;
    align-self: stretch;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: 0px 16px;
    position: relative;
  }
  
  .circle-bookstore .nav-label {
    color: #064e3b;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .circle-bookstore .button {
    all: unset;
    align-items: center;
    border: 1px solid;
    border-color: #064e3b;
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    height: 43px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
  }
  
  .circle-bookstore .text-container {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    padding: 0px 4px;
    position: relative;
  }
  
  .circle-bookstore .CTA {
    all: unset;
    box-sizing: border-box;
    color: #064e3b;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    text-shadow: 0px 4px 4px #00000040;
    white-space: nowrap;
    width: fit-content;
  }
  
  .circle-bookstore .text-container-wrapper {
    all: unset;
    align-items: center;
    background-color: #65a30d;
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 4px;
    justify-content: center;
    padding: 12px 20px;
    position: relative;
  }
  
  .circle-bookstore .text-wrapper {
    all: unset;
    box-sizing: border-box;
    color: #ecfdf5;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .circle-bookstore .property-image {
    align-items: flex-start;
    align-self: stretch;
    background-color: #ecfdf5;
    display: flex;
    flex-wrap: wrap;
    gap: 0px 0px;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 0;
  }
  
  .circle-bookstore .contact-copy {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 32px;
    height: 926px;
    justify-content: center;
    min-width: 320px;
    padding: 80px;
    position: relative;
  }
  
  .circle-bookstore .copy {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    max-width: 800px;
    position: relative;
    width: 100%;
  }
  
  .circle-bookstore .heading {
    align-self: stretch;
    color: #064e3b;
    font-family: "Domine-Regular", Helvetica;
    font-size: 56px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 67.2px;
    margin-top: -1px;
    position: relative;
  }
  
  .circle-bookstore .list-density {
    align-items: flex-start;
    background-color: #f7fbf2;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 1120px;
  }
  
  .circle-bookstore .list-item {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    min-height: 48px;
    position: relative;
    width: 100%;
  }
  
  .circle-bookstore .state-layer-overlay {
    height: 48px;
    left: 0;
    position: absolute;
    top: 0;
    width: 1120px;
  }
  
  .circle-bookstore .state-layer {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 16px;
    height: 48px;
    padding: 4px 16px;
    position: relative;
    width: 100%;
  }
  
  .circle-bookstore .leading-element {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  
  .circle-bookstore .building-blocks {
    background-color: #b7f1b9;
    border-radius: 100px;
    height: 40px;
    overflow: hidden;
    position: relative;
    width: 40px;
  }
  
  .circle-bookstore .initial {
    color: #2d4e30;
    font-family: var(--m3-title-medium-font-family);
    font-size: var(--m3-title-medium-font-size);
    font-style: var(--m3-title-medium-font-style);
    font-weight: var(--m3-title-medium-font-weight);
    height: 40px;
    left: 0;
    letter-spacing: var(--m3-title-medium-letter-spacing);
    line-height: var(--m3-title-medium-line-height);
    position: absolute;
    text-align: center;
    top: -1px;
    width: 40px;
  }
  
  .circle-bookstore .content {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    position: relative;
  }
  
  .circle-bookstore .headline {
    align-self: stretch;
    color: #181d18;
    font-family: var(--m3-body-large-font-family);
    font-size: var(--m3-body-large-font-size);
    font-style: var(--m3-body-large-font-style);
    font-weight: var(--m3-body-large-font-weight);
    letter-spacing: var(--m3-body-large-letter-spacing);
    line-height: var(--m3-body-large-line-height);
    position: relative;
  }
  
  .circle-bookstore .trailing-element {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 10px;
    margin-bottom: -4px;
    margin-top: -4px;
    position: relative;
  }
  
  .circle-bookstore .checkboxes {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: center;
    padding: 4px 4px 4px 0px;
    position: relative;
  }
  
  .circle-bookstore .div {
    align-items: center;
    border-radius: 100px;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    padding: 11px;
    position: relative;
  }
  
  .circle-bookstore .container {
    background-color: #fff;
    border-radius: 2px;
    height: 100%;
    position: relative;
    width: 100%; 
  }
  
  .circle-bookstore .check-small {
    height: 24px !important;
    left: 8px !important;
    position: absolute !important;
    top: 8px !important;
    width: 24px !important;
  }
  
  .circle-bookstore .div-wrapper {
    all: unset;
    align-items: center;
    background-color: #65a30d;
    border-radius: 8px;
    box-sizing: border-box;
    display: inline-flex;
    gap: 4px;
    height: 53px;
    justify-content: center;
    padding: 16px 24px;
    position: relative;
  }
  
  .circle-bookstore .CTA-wrapper {
    all: unset;
    align-items: center;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    margin-bottom: -4.5px;
    margin-top: -4.5px;
    padding: 4px;
    position: relative;
  }
  
  .circle-bookstore .CTA-2 {
    all: unset;
    box-sizing: border-box;
    color: #ecfdf5;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .circle-bookstore .type-simple {
    align-items: center;
    background-color: #ecfdf5;
    display: flex;
    flex-direction: column;
    gap: 64px;
    padding: 80px 64px;
    position: relative;
    width: 1280px;
  }
  
  .circle-bookstore .div-2 {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .circle-bookstore .column {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
  }
  
  .circle-bookstore .logo {
    flex: 0 0 auto;
    position: relative;
  }
  
  .circle-bookstore .links {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 32px;
    position: relative;
  }
  
  .circle-bookstore .link {
    color: #064e3b;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .circle-bookstore .actions {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 400px;
  }
  
  .circle-bookstore .heading-2 {
    align-self: stretch;
    color: #064e3b;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 27px;
    margin-top: -1px;
    position: relative;
  }
  
  .circle-bookstore .form {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .circle-bookstore .text-input {
    background-color: #ecfdf5;
    border: 1px solid;
    border-color: #064e3b;
    color: #059669;
    flex: 1;
    flex-grow: 1;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-bottom: -1px;
    margin-left: -1px;
    margin-top: -1px;
    padding: 12px;
    position: relative;
  }
  
  .circle-bookstore .button-wrapper {
    all: unset;
    align-items: center;
    border: 1px solid;
    border-color: #064e3b;
    box-sizing: border-box;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    justify-content: center;
    margin-bottom: -1px;
    margin-right: -1px;
    margin-top: -1px;
    padding: 12px 24px;
    position: relative;
  }
  
  .circle-bookstore .button-2 {
    all: unset;
    box-sizing: border-box;
    color: #064e3b;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .circle-bookstore .text {
    align-self: stretch;
    color: #064e3b;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 19.2px;
    position: relative;
  }
  
  .circle-bookstore .credits {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 32px;
    position: relative;
    width: 100%;
  }
  
  .circle-bookstore .divider {
    align-self: stretch;
    background-color: #064e3b;
    height: 1px;
    position: relative;
    width: 100%;
  }
  
  .circle-bookstore .footer-links {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 24px;
    position: relative;
  }
  
  .circle-bookstore .text-wrapper-2 {
    color: #064e3b;
    font-family: "Open Sans-Regular", Helvetica;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 22.4px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  